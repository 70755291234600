import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Layout from '../../components/layout';
import './index.sass';
import { images } from '../../components/read-speaker-for-sharepoint/data';
import { createMuiTheme } from '@material-ui/core/styles';
import SEO from '../../components/seo';
import SignUpSection from '../../components/signup';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Link as GLink } from 'gatsby';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#009cf3',
    },
  },
  typography: {
    fontSize: 17,
    fontFamily: ['Lato', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"'],
  },
  overrides: {
    MuiInput: {
      root: {
        paddingBottom: '0.2rem',
        marginBottom: '0.3rem',
      },
      underline: {
        '&:hover:before': {
          borderBottomWidth: '1px !important',
        },
        '&:after': {
          borderBottom: '#009cf3 solid 1px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#000',
      },
      shrink: {
        transform: 'translate(0, -4px) scale(0.75)',
      },
      error: {
        color: '#333 !important',
      },
    },
  },
});

const Index = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const openLightBox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightBox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  return (
    <Layout pageType="blog">
      <SEO
        keywords={[
          'sharepoint',
          'read speaker',
          'web speaker',
          'accessibility',
          'goverment',
          'spfx',
          'webpart',
        ]}
        title="Node Vision | ReadSpeaker Integration for SharePoint Online"
      />
      <div className="read-speaker">
        <section className="heading">
          <h1>ReadSpeaker &nbsp;Integration &nbsp;for &nbsp;SharePoint</h1>
        </section>
        <section className="landing">
          <div className="container">
            <div className="p1">
              Make your SharePoint site more accessible with{' '}
              <a href="https://www.readspeaker.com/" className="link">
                ReadSpeaker
              </a>{' '}
              solution
            </div>
            <div className="p2">
              <span className="node-vision">node vision</span> developed a
              solution which integrates ReadSpeaker TTS (text-to-speech) service
              with SharePoint Online. Just insert a web part into your
              SharePoint page and make it instantly more accessible and
              engaging.
              <br />
              <br />
              Contact us{' '}
              <GLink to="/#contact" className="link">
                here
              </GLink>{' '}
              for more information.
            </div>
            <div className="gallery-1 nv-gallery">
              <Gallery
                photos={images
                  .map((x, idx) => ({
                    ...x,
                    src: `/images/read-speaker/rs-0${idx + 1}-tn.jpg`,
                  }))
                  .slice(0, 3)}
                onClick={openLightBox}
                targetRowHeight={200}
                margin={6}
              />
            </div>
            <div className="video-container-wrapper">
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/PNPbvr2weIE"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </section>
        <SignUpSection />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightBox}>
              <Carousel
                currentIndex={currentImage}
                views={images.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Layout>
  );
};

export default Index;
