
export const images: Array<{ src: string, width: number, height: number }> = [
  {
    src: '/images/read-speaker/rs-01-min.jpeg',
    height: 1053,
    width: 1920,
    title: 'Read speaker - 1',
  },
  {
    src: '/images/read-speaker/rs-02-min.jpeg',
    height: 1200,
    width: 1920,
    title: 'Read speaker - 2',
  },

];
